//@ts-nocheck
import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import "../DemoComponentsStyles/Cards.css";
import IosShareIcon from "@mui/icons-material/IosShare";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { FirebaseAnalytics } from "../../common/FirebaseAnalytics";
import { FA_EVENTS } from "../../constants/fa_events";
import { RWebShare } from "react-web-share";
import { useParams } from "react-router-dom";
import { sharePoll } from "../../constants/api";
import { getUserProfile, getContestData } from "../../constants/api";
import ReactPixel from "react-facebook-pixel";
import Tour, { Step } from "reactour";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";

import styles from "../../styles/UpdatedGyanGG.module.css";

//@ts-ignore
import "../DemoComponentsStyles/Cards.css";
import { useNavigate } from "react-router-dom";
import {
  getNextPoll,
  getPoll,
  getPollBySharedId,
  getPollSummary,
  getSaveAnswer,
} from "../../constants/api";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 300,
      paddingLeft: 8,
      paddingRight: 8,
      minHeight: 60,
      marginTop: 10,
      top: 40,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },

  buttonText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "center",
    },
  },
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[100],
  "&:hover": {
    backgroundColor: grey[100],
  },
}));

const categories: Category[] = [
  { name: "Esports", key: "645572370e5ff19ad783ca11" },
  { name: "Politics", key: "6453ae54d000d95aa2eadaaf" },
  { name: "Technology", key: "6453b95f373d4c3a97eb33b3" },
  { name: "Sports", key: "6453b957b01af0a6578da170" },
  { name: "Business", key: "6453b97099f4ed29192c479a" },
  { name: "Entertainment", key: "6453ae53d000d95aa2eadaac" },
];

interface Category {
  name: string;
  key: string;
}

interface CategoryPillProps {
  category: Category;
  selected: boolean;
  onSelect: (category: Category) => void;
}

function CategoryPill({ category, selected, onSelect }: CategoryPillProps) {
  const handleClick = () => {
    onSelect(category);
  };

  const handleCrossClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    console.log(category, "category");
    onSelect(category);
  };

  return (
    <div
      className={styles.pills}
      style={{
        backgroundColor: selected ? "#D87C25" : "transparent",
        borderColor: selected ? "transparent" : "#868686",
      }}
      onClick={handleClick}
    >
      {category.name}
      {selected && (
        <span className={styles.cross} onClick={handleCrossClick}>
          <span>|</span>
          &times;
        </span>
      )}
    </div>
  );
}

const NewCard = () => {
  let { pollId, pathCategory } = useParams();
  const [isFlipped, setIsFlipped] = React.useState<boolean>(false);
  const [isKnowMore, setIsKnowMore] = React.useState<boolean>(false);
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const [getPolldata, setGetPollData] = React.useState<any>();
  const [summaryId, setSummaryId] = React.useState<string>("");
  const [summaryData, setSummaryData] = React.useState();
  const [getAnsData, setGetAnsData] = React.useState();
  const [optionStat, setOptionStat] = React.useState({});
  const navigate = useNavigate();
  const [showNext, setShowNext] = React.useState(false);
  const [showAns, setShowAns] = React.useState(false);
  const firebaseAnalyticsObjRef = React.useRef(new FirebaseAnalytics());
  const [selectedCategories, setSelectedCategories] = React.useState<
    SelectedCategory[]
  >([]);
  const [pathCategorySelected, setPathCategorySelected] = React.useState(true);
  const keys = selectedCategories.map((obj) => obj.key);
  // const [isTourOpen, setIsTourOpen] = React.useState(true);
  const [clicked, setClicked] = React.useState(false);
  const [showPrevious, setShowPrevious] = React.useState(false);
  const [currentPollIds, setCurrentPollIds] = React.useState(null);
  const [touchStartX, setTouchStartX] = React.useState(null);
  const [touchEndX, setTouchEndX] = React.useState(null);
  const [touchStartY, setTouchStartY] = React.useState(null);
  const [touchEndY, setTouchEndY] = React.useState(null);

  // const steps: Step[] = [
  //   {
  //     selector: `.${styles.image_content}`,
  //     content: () => (
  //       <div className={styles.swipe_container}>
  //         <div>
  //           <SwipeRightIcon
  //             sx={{
  //               height: "80px",
  //               width: "80px",
  //             }}
  //           />
  //         </div>
  //         <div>Swipe right to go to next poll</div>
  //       </div>
  //     ),
  //     style: {
  //       fontFamily: "Lato",
  //       fontSize: "21px",
  //       fontWeight: 700,
  //       height: "30vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "space-between",
  //       backgroundColor: "transparent",
  //       color: "white",
  //       width: "100%",
  //       textAlign: "center",
  //       marginTop: "2vh",
  //       transform: "translate(0,50vh)",
  //     },
  //   },
  //   {
  //     selector: `.${styles.categories_container}`,
  //     content: "Choose your favourite categories to poll on!",
  //     position: "bottom",
  //     style: {
  //       fontFamily: "Lato",
  //       fontSize: "21px",
  //       fontWeight: 700,
  //       height: "70vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "space-between",
  //       backgroundColor: "transparent",
  //       color: "white",
  //       marginTop: "6vh",
  //     },
  //   },
  //   {
  //     selector: `.${styles.rewards_image}`,
  //     content: "Answer 10 polls and win rewards!",
  //     style: {
  //       fontFamily: "Lato",
  //       fontSize: "21px",
  //       fontWeight: 700,
  //       height: "70vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "space-between",
  //       backgroundColor: "transparent",
  //       color: "white",
  //       // marginTop: "1vh",
  //     },
  //   },
  //   {
  //     selector: `.${styles.info_icon}`,
  //     content: () => (
  //       <div className={styles.info_btn_tour}>
  //         Click here to know more about the poll
  //       </div>
  //     ),
  //     position: "left",
  //     style: {
  //       fontFamily: "Lato",
  //       fontSize: "21px",
  //       fontWeight: 700,
  //       height: "30vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "space-between",
  //       backgroundColor: "transparent",
  //       color: "white",
  //       transform: "translate(0, 35vh)",
  //       marginRight: "10px",
  //     },
  //   },
  // ];

  const PrevButton = () => (
    <button className={styles.Okays} onClick={handleTourEnd}>
      Skip
    </button>
  );

  const NextButton = ({ onClick }) => (
    <button className={styles.Okay} onClick={onClick}>
      Next
    </button>
  );

  // const handleTourEnd = () => {
  //   setIsTourOpen(false);
  //   localStorage.setItem("isGuideAlreadyDisplayed", true);
  // };

  const handleSelectCategory = (category: Category, fetchPoll?: boolean) => {
    const categoryIndex = selectedCategories.findIndex(
      (c) => c.key === category.key
    );

    if (categoryIndex === -1) {
      const keyIndex = keys.indexOf(category.key);
      if (keyIndex === -1) {
        keys.push(category.key);
      }

      firebaseAnalyticsObjRef.current.setUserId(
        localStorage.getItem("userId")!
      );
      firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.SELECT_CATEGORY, {
        categoryId: category.key,
        categoryName: category.name,
        screenName: "GyaanGG",
      });

      setSelectedCategories([...selectedCategories, category]);
    } else {
      const updatedSelectedCategories = selectedCategories.filter(
        (c) => c.key !== category.key
      );
      if (
        pathCategory &&
        category &&
        category.name.toLowerCase() === pathCategory.toLowerCase()
      ) {
        localStorage.removeItem("sharedCategory");
        setPathCategorySelected(false);
      }
      const keyIndex = keys.indexOf(category.key);
      if (keyIndex >= 0) {
        keys.splice(keyIndex, 1);
      }
      setSelectedCategories(updatedSelectedCategories);
    }

    if (fetchPoll === undefined || fetchPoll === null || fetchPoll === true) {
      getPoll("", keys).then((res) => {
        setGetPollData(res);
        setSummaryId(res?.id);
        localStorage.removeItem("sharedPollId");
      });
    }
  };

  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.setItem(`sharedPollId`, pollId);
      localStorage.setItem("sharedCategory", pathCategory);
      navigate("/");
    } else {
      let newPollId = pollId;
      let nCategory = pathCategory;
      if (!nCategory) {
        nCategory = localStorage.getItem("sharedCategory");
      }

      if (nCategory === "undefined" || nCategory === "null") {
        nCategory = "";
        localStorage.removeItem("sharedCategory");
      }

      if (pathCategorySelected && nCategory) {
        const idx = categories.findIndex(
          (element) => element.name.toLowerCase() === nCategory?.toLowerCase()
        );
        if (idx >= 0) {
          const categoryMapping = categories[idx];
          if (!keys.includes(categoryMapping.key)) {
            keys.push(categoryMapping.key);
            handleSelectCategory(categoryMapping, false);
          }
        }
      }

      if (!newPollId) {
        newPollId = localStorage.getItem("sharedPollId");
      }
      if (newPollId === "undefined" || newPollId === "null") {
        newPollId = undefined;
        localStorage.removeItem("sharedPollId");
      }

      getPoll(newPollId ? newPollId : "", keys).then((res) => {
        setGetPollData(res);
        setSummaryId(res?.id);
        localStorage.removeItem("sharedPollId");
      });
    }
  }, [pollId, getSaveAnswer, setSummaryData]);

  // React.useEffect(() => {
  //   setIsTourOpen(
  //     !(localStorage.getItem("isGuideAlreadyDisplayed") === "true")
  //   );
  // }, [localStorage.getItem("isGuideAlreadyDisplayed")]);

  const fetchContest = async () => {
    try {
      const res = await getContestData();
      if (res.displayPopup === true) {
        navigate("/contest");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProfileData = async () => {
    try {
      const profileData = await getUserProfile();
      localStorage.setItem("profileData", JSON.stringify(profileData));
    } catch (error) {
      console.log(`Error occurred while fetching user profile.`);
    }
  };

  const handleClick = React.useCallback((summaryId, ans) => {
    setShowAns(true);
    setGetAnsData();
    setOptionStat({});
    ReactPixel.trackCustom("track", FA_EVENTS.CLICK_POLL_ANSWER);
    getSaveAnswer(summaryId, ans).then((res) => {
      setGetAnsData(res);
      setOptionStat(res.optionStats);
      fetchContest();
      fetchProfileData();
    });
    setShowAns(false);
    setIsFlipped(true);
  }, []);

  const handleNext = (pollId: string, hasNextPollClicked: boolean) => {
    setClicked(false);
    setShowNext(true);
    setCurrentPollIds(pollId);
    let eventName = FA_EVENTS.CLICK_NEXT;
    if (hasNextPollClicked) {
      eventName = FA_EVENTS.CLICK_NEXT_POLL;
    }
    firebaseAnalyticsObjRef.current.setUserId(localStorage.getItem("userId")!);
    firebaseAnalyticsObjRef.current.sendEvent(eventName, {
      pollId: pollId,
      screenName: "GyaanGG",
    });

    ReactPixel.trackCustom("track", eventName);

    let nCategory = pathCategory;
    if (!nCategory) {
      nCategory = localStorage.getItem("sharedCategory");
    }

    if (nCategory === "undefined" || nCategory === "null") {
      nCategory = "";
      localStorage.removeItem("sharedCategory");
    }

    if (pathCategorySelected && nCategory) {
      const idx = categories.findIndex(
        (element) => element.name.toLowerCase() === nCategory?.toLowerCase()
      );
      if (idx >= 0) {
        const categoryMapping = categories[idx];
        if (!keys.includes(categoryMapping.key)) {
          keys.push(categoryMapping.key);
          handleSelectCategory(categoryMapping, false);
        }
      }
    }

    getNextPoll(keys).then((res) => {
      setIsFlipped(false);
      setGetPollData(res);
      setShowNext(false);
    });
  };

  const handlePrev = (pollId: string, hasNextPollClicked: boolean) => {
    if (pollId !== getPolldata?.id) {
      setShowPrevious(true);
      getPoll(pollId).then((res) => {
        setIsFlipped(false);
        setGetPollData(res);
        setShowPrevious(false);
      });
    }
  };

  const handleShareClick = (item) => {
    console.log(JSON.stringify(item));
    firebaseAnalyticsObjRef.current.setUserId(localStorage.getItem("userId")!);
    firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.CLICK_SHARE, {
      category: JSON.stringify(item?.hashtags),
      articleTitle: item?.articleTitle,
      pollQuestion: item?.question,
      articleId: item?.articleId,
      pollId: item?.id,
      screenName: "GyaanGG",
    });
    sharePoll(item?.id).then((res) => {
      console.log(`share details shared with backend.`);
    });
  };

  const handleSummaryDetails = (id) => {
    setSummaryData();
    getPollSummary(id).then((res) => {
      setSummaryData(res);
    });
    setShowCard(true);
    setIsKnowMore(false);
  };

  const getSelectedCategory = (hashtags: string[]) => {
    let cat = 'polls';
    for (const hashtag of hashtags) {
      if (hashtag.toLowerCase() === 'esports') {
        cat = 'Esports';
        break;
      }
      if (hashtag.toLowerCase() === 'gaming') {
        cat = 'Gaming';
      }
      if (hashtag.toLowerCase() === 'technology') {
        cat = 'Technology';
      }
      if (hashtag.toLowerCase() === 'entertainment') {
        cat = 'Entertainment';
      }
      if (hashtag.toLowerCase() === 'politics') {
        cat = 'Politics';
      }
      if (hashtag.toLowerCase() === 'business') {
        cat = 'Business';
      }
      if (hashtag.toLowerCase() === 'sports') {
        cat = 'Sports';
      }
    }
    return cat;
  }

  const classes = useStyles();
  return (
    <>
      <div className={styles.categories_container}>
        {categories.map((category) => (
          <CategoryPill
            key={category.key}
            category={category}
            selected={selectedCategories.some((c) => c.key === category.key)}
            onSelect={handleSelectCategory}
          />
        ))}
      </div>
      {isFlipped === false ? (
        <>
          {showCard === false ? (
            <>
              <div
                className={`${styles.container} ${
                  showNext ? styles["show-next"] : ""
                } ${showPrevious ? styles["show-previous"] : ""}`}
                onTouchStart={(e) => {
                  setTouchStartX(e.touches[0].clientX);
                  setTouchStartY(e.touches[0].clientY);
                }}
                onTouchMove={(e) => {
                  setTouchEndX(e.touches[0].clientX);
                  setTouchEndY(e.touches[0].clientY);
                }}
                onTouchEnd={() => {
                  if (touchStartX && touchEndX && touchStartY && touchEndY) {
                    const diffX = touchStartX - touchEndX;
                    const diffY = touchStartY - touchEndY;

                    if (Math.abs(diffX) > Math.abs(diffY)) {
                      if (diffX < 50) {
                        handleNext(getPolldata?.id, false);
                      }
                      if (diffX > 50 && currentPollIds) {
                        handlePrev(currentPollIds, false);
                      }
                    }
                  }
                  setTouchStartX(null);
                  setTouchEndX(null);
                  setTouchStartY(null);
                  setTouchEndY(null);
                }}
              >
                <div className={styles.image_container}>
                  <div className={styles.image_box}>
                    <img
                      src={
                        getPolldata?.image?.thumbnail
                          ? getPolldata?.image?.thumbnail
                          : "./assets/images/Gyaan.png"
                      }
                    ></img>
                  </div>

                  <div className={styles.image_content}>
                    {/* <div
                      className={`${styles.next} ${
                        clicked ? styles.clicked : ""
                      }`}
                      style={{
                        backgroundColor: clicked ? "#D87C25" : "#FFFFFF",
                      }}
                      onClick={() => {
                        setClicked(true);
                        firebaseAnalyticsObjRef.current.setUserId(
                          localStorage.getItem("userId")!
                        );
                        firebaseAnalyticsObjRef.current.sendEvent(
                          FA_EVENTS.CLICK_FLAG,
                          {
                            pollId: getPolldata?.id,
                            screenName: "GyaanGG",
                          }
                        );
                      }}
                    >
                      <svg className={styles.icons}>
                        <use xlinkHref="/assets/sprite.svg#icon-flag" />
                      </svg>
                      Flag Poll
                    </div> */}

                    <div
                      className={styles.next}
                      onClick={() => handleNext(getPolldata?.id, false)}
                    >
                      Next
                      <svg className={styles.icons}>
                        <use xlinkHref="/assets/sprite.svg#icon-arrow-right2" />
                      </svg>
                    </div>

                    <div className={styles.padding_content}>
                      <div className={styles.tag}>
                        {getPolldata &&
                          getPolldata.hashtags &&
                          getPolldata.hashtags[0]}
                      </div>

                      <div className={styles.question}>
                        <div className={styles.question_width}>
                          {getPolldata && getPolldata.question}
                        </div>
                        <div className={styles.info_icon}>
                          <InfoIcon
                            sx={{
                              color: "#D87C25",
                            }}
                            onClick={() => {
                              firebaseAnalyticsObjRef.current.setUserId(
                                localStorage.getItem("userId")
                              );
                              firebaseAnalyticsObjRef.current.sendEvent(
                                FA_EVENTS.CLICK_BULB,
                                {
                                  pollId: getPolldata.id,
                                  articleId: getPolldata.articleId,
                                  pollQuestion: getPolldata.question,
                                  articleTitle: getPolldata.articleTitle,
                                  category: JSON.stringify(
                                    getPolldata?.hashtags
                                  ),
                                  screenName: "GyaanGG",
                                }
                              );
                              handleSummaryDetails(getPolldata.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.options_container}>
                  {getPolldata &&
                    getPolldata?.options?.map((item) => {
                      return (
                        <div className={styles.options}>
                          <button
                            className={styles.options_button}
                            onClick={() => {
                              firebaseAnalyticsObjRef.current.setUserId(
                                localStorage.getItem("userId")!
                              );
                              firebaseAnalyticsObjRef.current.sendEvent(
                                FA_EVENTS.CLICK_POLL_ANSWER,
                                {
                                  pollId: getPolldata.id,
                                  selectedChoice: item,
                                  pollQuestion: getPolldata.question,
                                  category: JSON.stringify(
                                    getPolldata.hashtags
                                  ),
                                  publishedDateTime: getPolldata.publishedDate,
                                  articleTitle: getPolldata.articleTitle,
                                  articleId: getPolldata.articleId,
                                  screenName: "GyaanGG",
                                }
                              );
                              handleClick(getPolldata.id, item);
                            }}
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                  {/* <div className={styles.people_responded}>
                    <h2>{getPolldata && getPolldata.totalResponse}</h2>
                    <span>people voted</span>
                  </div> */}
                  {getPolldata ? (
                    <div className={styles.swipenew}>
                      Swipe right for next
                      <span className={styles.moveit}>
                        <svg className={styles.swipenewicon}>
                          <use xlinkHref="/assets/sprite.svg#icon-chevron-right" />
                        </svg>
                        <svg className={styles.swipenewicons}>
                          <use xlinkHref="/assets/sprite.svg#icon-chevron-right" />
                        </svg>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.container_knowmore}>
                <div className={styles.knowmore_image_container}>
                  <div className={styles.image}></div>
                  <img
                    src={
                      getPolldata?.image?.thumbnail
                        ? getPolldata?.image?.thumbnail
                        : "./assets/images/Gyaan.png"
                    }
                  ></img>

                  <div className={styles.image_content_knowmore}>
                    <div
                      className={styles.back_btn}
                      onClick={() => {
                        setIsKnowMore(false);
                        setShowCard(false);
                      }}
                    >
                      <svg className={styles.icons}>
                        <use xlinkHref="/assets/sprite.svg#icon-arrow-left2" />
                      </svg>
                      Go Back
                    </div>
                    <div className={styles.padding_content}>
                      <div className={styles.question}>
                        {summaryData && summaryData.question}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.summary}>
                  <p className={styles.summary_font}>
                    {summaryData && summaryData.summary}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div
            className={`${styles.container_answer} ${
              showNext ? styles["show-nexts"] : ""
            }`}
            onTouchStart={(e) => {
              setTouchStartX(e.touches[0].clientX);
              setTouchStartY(e.touches[0].clientY);
            }}
            onTouchMove={(e) => {
              setTouchEndX(e.touches[0].clientX);
              setTouchEndY(e.touches[0].clientY);
            }}
            onTouchEnd={() => {
              if (touchStartX && touchEndX && touchStartY && touchEndY) {
                const diffX = touchStartX - touchEndX;
                const diffY = touchStartY - touchEndY;

                if (Math.abs(diffX) > Math.abs(diffY)) {
                  if (diffX < 40) {
                    handleNext(getPolldata?.id, true);
                  }
                }
              }
              setTouchStartX(null);
              setTouchEndX(null);
              setTouchStartY(null);
              setTouchEndY(null);
            }}
          >
            <div className={styles.answer_container}>
              <div className={styles.image_box}>
                {!getAnsData ? (
                  ""
                ) : (
                  <img
                    src={
                      getAnsData?.image?.thumbnail
                        ? getAnsData?.image?.thumbnail
                        : "./assets/images/Gyaan.png"
                    }
                  ></img>
                )}
              </div>

              <div className={styles.image_content}>
                <div
                  className={styles.next_poll}
                  onClick={() => handleNext(getAnsData?.id, true)}
                >
                  Next Poll
                  <svg className={styles.icons}>
                    <use xlinkHref="/assets/sprite.svg#icon-arrow-right2" />
                  </svg>
                </div>
                <div className={styles.padding_content}>
                  <div className={styles.question}>
                    {getAnsData && getAnsData.question}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.options_container}>
              {Object.keys(optionStat).map((key, index) => {
                return (
                  <div className={styles.width}>
                    <div
                      key={index}
                      className={
                        key === getAnsData.selectedOption
                          ? styles.selected_progress
                          : styles.progress
                      }
                      style={{ width: `${optionStat[key]}%` }}
                    >
                      <div className={styles.bar_number}>
                        <p>{key}</p>
                        <p>{optionStat[key]}%</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              {!getAnsData ? (
                ""
              ) : (
                <>
                  {/* <div className={styles.answer_responded}>
                    <h2>{getAnsData && getAnsData.agreedOptionCount}</h2>
                    <span>people agreed with you!</span>
                  </div> */}
                  <div>
                    <RWebShare
                      data={{
                        text: `${getPolldata?.question} \nAnswer polls and stand a chance to win an iPhone 14!`,
                        url: 
                          `https://gyaangpt.com/`+
                          `${getPolldata?.hashtags && getPolldata?.hashtags.length > 0 ? getSelectedCategory(getPolldata?.hashtags) : 'polls'}/`+
                          `${getPolldata?.id}`,
                        title: "Gyaan",
                      }}
                      onClick={() => handleShareClick(getPolldata)}
                    >
                      <ColorButton
                        variant="contained"
                        sx={{
                          gap: "15px",
                          borderRadius: "10px",
                          fontFamily: "Lato",
                          fontStyle: "normal",
                          fontWeight: 700,
                          position: "unset",
                          textTransform: "none",
                        }}
                        className={classes.button}
                        startIcon={<IosShareIcon />}
                      >
                        <span className={classes.buttonText}>
                          Share this poll
                        </span>
                      </ColorButton>
                    </RWebShare>
                  </div>
                  {getAnsData ? (
                    <div className={styles.swipenew}>
                      Swipe right for next
                      <span className={styles.moveit}>
                        <svg className={styles.swipenewicon}>
                          <use xlinkHref="/assets/sprite.svg#icon-chevron-right" />
                        </svg>
                        <svg className={styles.swipenewicons}>
                          <use xlinkHref="/assets/sprite.svg#icon-chevron-right" />
                        </svg>
                      </span>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {/* {isTourOpen && (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={handleTourEnd}
          lastStepNextButton={
            <button className={styles.Okay} onClick={handleTourEnd}>
              Okay!
            </button>
          }
          prevButton={<PrevButton />}
          nextButton={<NextButton />}
          accentColor="#D87C25"
          className={styles.tour}
          rounded={8}
          disableKeyboardNavigation={true} // keyboard navigation fasle because it's for mobile and tablet use.
          disableDotsNavigation={true}
          disableFocusLock={true}
          showCloseButton={false}
          showNavigation={false}
          showNumber={false} // show number on tour
          maskClassName={styles.mask}
          disableInteractionClassName={styles.disableInteraction}
          startAt={0}
          customStyles={styles.custom_styles}
        ></Tour>
      )} */}
    </>
  );
};

export default React.memo(NewCard);
