//@ts-nocheck
import React from "react";
import axios from "axios";

export const api = "https://api.pollgpt.in";

const getToken: any = localStorage.getItem("token");
export const token = () => (getToken ? JSON.parse(getToken) : null);

export const getContestData = async () => {
  try {
    const response = await axios
      .get(`${api}/has-entered-in-contest`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const UpdateNumber = async (number: string) => {
  try {
    const response = await axios.put(
      `${api}/users/updatePhone`,
      { mobileNo: number },
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Error: " + e);
  }
};

export const searchGyaanGpt = async (searchedText: string | undefined) => {
  try {
    if (searchedText) {
      const response = await axios
        .post(
          `${api}/gpt/search`,
          {
            q: searchedText,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res: any) => {
          return res?.data;
        })
        .catch((error: any) => {
          return error;
        });
      if (response) return response;
      else return "Something went wrong";
    }
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const exploreGyanGpt = async (
  pageNo: number,
  token: any,
  limit = 20
) => {
  try {
    const response = await axios
      .get(`${api}/gpt/explore?offset=${pageNo * limit}&limit=${limit}`, {
        headers: token
          ? { Authorization: "Bearer " + token }
          : {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
      })
      .then((res: any) => {
        return res?.data?.list;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

// export const signIn = async (user: any) => {
//   try {
//     const response = fetch(`${api}/auth`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         emailId: user?.email,
//         name: user?.name,
//         pictures: user?.picture,
//         platform: "web",
//       }),
//     }).then(async (response) => {
//       return response.json().then((responseJson) => {
//         localStorage.setItem("token", JSON.stringify(responseJson?.token));
//         return true
//       });
//     });
//     if (response) return response;
//     else return "Something went wrong";
//   } catch (e) {
//     throw new Error("Error :" + e);
//   }
// };

export const generatePoll = async (id: string, page: number) => {
  try {
    const response = await axios
      .get(`${api}/gpt/articles/${id}/polls?page=${page || 0}&size=3`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data?.list;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const sharePoll = async (id: string) => {
  try {
    if (id) {
      const response = await axios
        .post(
          `${api}/share-polls`,
          {
            pollId: id,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res: any) => {
          return res?.data;
        })
        .catch((error: any) => {
          return error;
        });
      if (response) return response;
      else return "Something went wrong";
    }
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

// curl --location --globoff 'https://api.gyaan.gg/polls/v1/categories
// ?filters={%22categoryIds%22%3A%20[%226453ae53d000d95aa2eadaac%22%2C%20%226453b957b01af0a6578da170%22]}'

export const getPoll = async (id: string, categoryIds: string[]) => {
  try {
    let url = `${api}/polls`;

    if (id) {
      url = `${url}/${id}`;
    } else if (categoryIds && categoryIds.length > 0) {
      url = `${url}/v1/categories?filters={"categoryIds": ${JSON.stringify(
        categoryIds
      )}}`;
    }

    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getNextPoll = async (categoryIds: string[]) => {
  try {
    let url = `${api}/polls`;
    if (!categoryIds || categoryIds.length === 0) {
      url = `${url}/next/poll`;
    } else {
      url = `${url}/v1/categories?filters={"categoryIds": ${JSON.stringify(
        categoryIds
      )}}`;
    }
    // `${api}/polls/next/poll`
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getPollBySharedId = async (id: string) => {
  try {
    const response = await axios
      .get(`${api}/share-polls/${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getPollSummary = async (id: string) => {
  try {
    const response = await axios
      .get(`${api}/polls/${id}/summary`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getSaveAnswer = async (id: string, ans: string) => {
  try {
    if (id) {
      const response = await axios
        .post(
          `${api}/polls/${id}/answers`,
          {
            selectedOption: ans,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res: any) => {
          return res?.data;
        })
        .catch((error: any) => {
          return error;
        });
      if (response) return response;
      else return "Something went wrong";
    }
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getRewards = async () => {
  try {
    const response = await axios
      .get(`${api}/rewards`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getUserProfile = async () => {
  try {
    const response = await axios
      .get(`${api}/users`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};

export const getMyPolls = async (page: number, limit: number) => {
  try {
    const response = await axios
      .get(`${api}/users/polls?offset=${page * limit}&limit=${limit}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res: any) => {
        return res?.data;
      })
      .catch((error: any) => {
        return error;
      });
    if (response) return response;
    else return "Something went wrong";
  } catch (e) {
    throw new Error("Error :" + e);
  }
};
