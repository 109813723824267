import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Avatar, Button, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import ProfileDrawer from "./Profile/ProfileDrawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "../DemoComponentsStyles/Header.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FirebaseAnalytics } from "../../common/FirebaseAnalytics";
import { FA_EVENTS } from "../../constants/fa_events";

import styles from "../../styles/UpdatedGyanGG.module.css";

const Header = () => {
  const [isOpenProfileDrawer, setIsOpenProfileDrawer] =
    React.useState<boolean>(false);
  const [isSelectDrop, SetIsSelectDrop] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<string>(
    useLocation().pathname
  );
  const navigate = useNavigate();
  const [tabName, setTabName] = React.useState("Answer");
  const [tabImageUrl, setTabImageUrl] = React.useState(
    "./assets/images/Vector3.png"
  );
  const firebaseAnalyticsObjRef = useRef(new FirebaseAnalytics());
  const location = useLocation();

  React.useEffect(() => {
    if (!selectedOption || selectedOption === "/") {
      setTabName("Answer");
      setTabImageUrl("./assets/images/Vector3.png");
      localStorage.setItem("active-tab-name", "gg");
      navigate("/gg");
    } else if (selectedOption === "/gg") {
      setTabName("Answer");
      setTabImageUrl("./assets/images/Vector3.png");
      localStorage.setItem("active-tab-name", "gg");
      navigate("/gg");
    } else if (selectedOption.startsWith("/polls/")) {
      setTabName("Answer");
      setTabImageUrl("../assets/images/Vector3.png");
      localStorage.setItem("active-tab-name", "gg");
    } else if (selectedOption === "/gpt") {
      setTabName("Explore");
      setTabImageUrl("./assets/images/MaskGroup2.png");
      localStorage.setItem("active-tab-name", "gpt");
      navigate("/gpt");
    } else {
      setTabName("Answer");
      setTabImageUrl("../assets/images/Vector3.png");
      localStorage.setItem("active-tab-name", "gg");
    }
  }, [selectedOption]);

  React.useEffect(() => {
    if (location.pathname === "/gg") {
      setTabName("Answer");
      setTabImageUrl("./assets/images/Vector3.png");
      localStorage.setItem("active-tab-name", "gg");
      navigate("/gg");
    } else if (location.pathname === "/gpt") {
      setTabName("Explore");
      setTabImageUrl("./assets/images/MaskGroup2.png");
      localStorage.setItem("active-tab-name", "gpt");
      navigate("/gpt");
    }
  }, [location.pathname]);

  const handleClick = () => {
    setSelectedOption("/gg");
    SetIsSelectDrop(false);
    firebaseAnalyticsObjRef.current.setUserId(localStorage.getItem("userId")!);
    firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.CLICK_EXPLORE, {
      screenName: "GyaanGG",
    });
  };

  const handleClicks = () => {
    setSelectedOption("/gpt");
    SetIsSelectDrop(false);
    firebaseAnalyticsObjRef.current.setUserId(localStorage.getItem("userId")!);
    firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.CLICK_ANSWER, {
      screenName: "GyaanGPT",
    });
  };

  return (
    <div className="">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <div className={styles.newHeader}>
          <img src="../assets/images/Vector3.png" alt="Logo Gyaan Image" />
          Gyaan
        </div>
        {/* <Button
          style={{
            color: "White",
            borderWidth: "1px",
            minWidth: "130px",
            justifyContent: "center",
            textTransform: "none",
          }}
          onClick={() => SetIsSelectDrop(true)}
        >
          <img src={tabImageUrl} style={{ margin: "4px" }} />
          <Typography
            style={{ fontSize: "18px", fontWeight: 600, fontFamily: "Lato" }}
          >
            {tabName}
          </Typography>
          <KeyboardArrowDownIcon />
        </Button> */}
        <Box
          style={{
            display: "flex",
            // width: "55%",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <div className={styles.rewards_image}>
            <img
              onClick={() => {
                firebaseAnalyticsObjRef.current.setUserId(
                  localStorage.getItem("userId")!
                );
                firebaseAnalyticsObjRef.current.sendEvent(
                  FA_EVENTS.CLICK_REWARD,
                  {
                    screenName: tabName === "Explore" ? "GyaanGG" : "GyaanGPT",
                  }
                );
                navigate("/rewards");
              }}
              src="../assets/images/Rewards Icons.png"
              style={{
                height: "30px",
                marginTop: "12px",
                marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "10px",
              }}
              alt="reward-img"
            />
          </div>
          {/* <Avatar
            style={{ marginLeft: "20px", border: "1px solid black", alignItems: 'center' }}
            alt="Remy Sharp"
            src="./assets/images/Gyaan1.png"
            onClick={() => {
              firebaseAnalyticsObjRef.current.setUserId(localStorage.getItem('userId')!);
              firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.CLICK_PROFILE, {
                screenName: tabName === 'Explore' ? 'GyaanGG' : 'GyaanGPT',
              });
              setIsOpenProfileDrawer(true);
            }}
          /> */}
          <MenuIcon
            style={{
              marginLeft: "3px",
              height: "45px",
              width: "40px",
              marginTop: "3px",
              fill: "#D87C25",
            }}
            onClick={() => {
              firebaseAnalyticsObjRef.current.setUserId(
                localStorage.getItem("userId")!
              );
              firebaseAnalyticsObjRef.current.sendEvent(
                FA_EVENTS.CLICK_PROFILE,
                {
                  screenName: tabName === "Explore" ? "GyaanGG" : "GyaanGPT",
                }
              );
              setIsOpenProfileDrawer(true);
            }}
          />
        </Box>
      </Box>

      {isSelectDrop && (
        <>
          <Backdrop
            sx={{
              color: "rgba(0, 0, 0, 0.8)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isSelectDrop}
            onClick={() => SetIsSelectDrop(false)}
          ></Backdrop>
          <Box sx={{ display: "flex", position: "absolute", width: "100%" }}>
            <Button
              style={{
                background: "rgb(3 11 19)",
                top: "-5px",
                width: "100%",
                height: "80px",
                borderRadius: 0,
                borderBottom:
                  selectedOption === "/gpt"
                    ? "4px solid rgb(216 125 38)"
                    : "none",
                borderRight: "1px solid rgb(28 35 42)",
                textTransform: "none",
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              onClick={handleClicks}
            >
              <img
                src="../assets/images/MaskGroup2.png"
                style={{ margin: "8px" }}
              />
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                Explore
              </Typography>
            </Button>
            <Button
              style={{
                background: "rgb(3 11 19)",
                top: "-5px",
                width: "100%",
                height: "80px",
                borderRadius: 0,
                borderBottom:
                  selectedOption === "/gg"
                    ? "4px solid rgb(216 125 38)"
                    : "none",
                textTransform: "none",
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              onClick={handleClick}
            >
              <img
                src="../assets/images/Vector3.png"
                style={{ margin: "8px" }}
              />
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                Answer
              </Typography>
            </Button>
          </Box>
        </>
      )}
      <GoogleOAuthProvider clientId="657162354902-6i7ib5ju94bifel4c438hkp6r129iip6.apps.googleusercontent.com">
        <ProfileDrawer
          setIsOpenProfileDrawer={setIsOpenProfileDrawer}
          isOpenProfileDrawer={isOpenProfileDrawer}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default Header;
