import React from "react";
import { Card, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import { Box, Typography } from "@material-ui/core";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { useNavigate } from "react-router-dom";

const TermsConditions = () => {
  const navigate = useNavigate();

  return (
    <Container
      fixed
      maxWidth="sm"
      // ref={containerRef}
      style={{ backgroundColor: "black" }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          alignItems: "center",
        }}
      >
        <IconButton>
          <KeyboardBackspaceRoundedIcon
            onClick={() => {
              const activeTab = localStorage.getItem("active-tab-name");
              if (!activeTab || activeTab === "gg") {
                localStorage.setItem("active-tab-name", "gg");
                navigate("/gg");
              } else {
                localStorage.setItem("active-tab-name", "gpt");
                navigate("/gpt");
              }
            }}
            style={{ color: "white" }}
          />
        </IconButton>
        <Typography
          style={{
            fontWeight: 900,
            fontSize: "20px",
            marginLeft: "3px",
            color: "white",
            fontFamily: "Lato",
          }}
        >
          Terms And Condition
        </Typography>
      </Box>
      <Box
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          marginBottom: "60px",
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            margin: "10px",
            overflow: "auto",
          }}
        >
          <Box style={{ backgroundColor: "white", height: "10px" }} />
          <Typography
            style={{
              marginBottom: "40px",
              fontFamily: "Lato",
              fontSize: "17px",
              color: "#000",
            }}
          >
            PLEASE READ OUR STANDARD TERMS OF USE PRIOR TO USING OUR WEBSITE. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY NOTICE OR OUR TERMS OF SERVICE, PLEASE DO NOT USE ANY OF THE SERVICES.
            Gyaan is a subsidiary of ASA Gyaan Pvt. Ltd., through the website https://www.gyaangpt.com.
            Gyaan's Terms & Conditions apply to users, participants, and customers of its web application
            Any and all individuals dealing with Gyaan are subject to the following Terms and Conditions hereinafter also referred to as general user conditions. While Visiting Gyaan website you undertake to abide and agree to be bound by the privacy policy of the company and these general user conditions set by ASA Gyaan Pvt. Ltd., which it shall be free to modify at any time without informing you of such changes. In order to stay informed, it is advised that you regularly refer to the Terms and Conditions, which are available on the site.
            Customer’s Responsibilities and Obligations
            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Mobile Number for rewards update and CookieID to uniquely identify the user. Installed package information for in-app incentive offers. The information that we request will be only used to provide in app incentive offers.
            Provide True Details
            Provide your legal full name, a valid mobile number, and any other information requested in order to complete the free trial request, registration, and onboarding process for using the Service.
            Compliance with Law
            Comply with all applicable laws and regulations with respect to its activities under this agreement.
            Authorized Users Access
            Ensure that the Authorized Users use the Services in accordance with the terms and conditions of this agreement and the Customer shall be responsible for any Authorized User’s breach of this agreement.
            Specifications Compliance
            Ensure that its network, devices, and systems comply with the relevant specifications provided by the Company from time to time.
            Account Security
            Maintaining the security of your account and password.
            Take responsibility for all content posted and activity that occurs under your account including Authorized Users and Users leaving Feedback.
            You must not modify, adapt, copy, clone, or hack the Service.
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by the Company.
            Rewards for Responses
            The Company may offer one or more rewards programs (“Rewards Programs“) under which you may have the opportunity to win rewards.
            As a reward for responding to a poll, Gyaan may provide the Member with a reward. A new winner is announced when requisite platform metrics are met whose identity is to be determined solely by Gyaan.
            Gyaan shall decide on the reward, and reserves the right to replace the reward with another without prior notice. Gyaan will also determine the method of the provision of the reward and the specific promotional period in which the free gift is available for each Poll and will notify the Member of the same.
            Gyaan will send the reward obtained to the Member’s, mobile number. If the reward is not delivered due to a flaw or mistake in the registration information, Gyaan shall not resend or reissue the free gift nor will the Member’s coins be replaced.
            If a reward has not been delivered to a Member within a reasonable time from the date of winning, the Member shall notify Gyaan at gyaangpt@gmail.com
            Gyaan also shall not be liable for any transaction failures that are the result of incorrect or inaccurate registered account information or address that is required for providing the reward.
            Gyaan reserves the right to cancel/forfeit rewards if we have reason to believe that Data provided by the Member is false or inaccurate, or if the Member commits any other prohibited act(s) described.
            Gyaan reserves the right to cancel/forfeit reward if the Member does not sign in for a period of 365 days or more.
            Support and Hosting ( Applicable for customers, not participants )
            In accordance with the plans you subscribe to, technical support is only provided via email and/or phone.
            You understand that the Company uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. The company reserves the right to change its third-party vendors without notice.
            Application Updates and Maintenance
            The Company reserves the right, from time to time, to modify, update or discontinue, temporarily or permanently, any part of the Service with or without notice. Such updates may include rolling out new features, Improvements, regular maintenance, correcting errors, and/or removing features.
            From time to time, Company will perform scheduled maintenance to ensure systems are optimum and any modifications are rolled out. The Service in part or in full may not be available during these times. The Company will take reasonable care to ensure the scheduled maintenance does not disrupt access to Service to whatever extent possible.
            No Warranties, Limitation of Liability
            Your use of the Service is at your sole risk. The service is provided on an “as is” and “as available” basis.
            The Company does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, or (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected.
            You expressly understand and agree that the Company shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.
            The terms in this section will be applicable to you whether or not Company, its representatives, Employees, Partners, or Resellers have advised of any Services, Features, or Offerings by the Company.
            Summary
            Gyaan does not accept responsibility for any direct, indirect, incidental, special, or consequential loss or damage including, without limitation, any loss of profits, business interruption, loss of programs, or other data on your information handling system or otherwise incurred by you or any third party arising from your access to or use of the Gyaan website or any site which is linked directly or indirectly to any pages on the Gyaan website/ app Information provided may be incomplete, out of date or inaccurate and may contain technical inaccuracies or typographical errors. Users should always seek appropriate legal advice before taking or refraining from taking any action.
            It is your responsibility to ensure that your access to the Gyaan website is free from viruses, worms, Trojan horses, and any other items of a destructive nature. Gyaan accepts no responsibility for any direct, indirect, incidental, special, or consequential loss or damage for loss of profits, data, or use incurred by you or any third party arising from your access to or use of the Gyaan website or any site which is linked directly or indirectly to any pages on the Gyaan.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsConditions;
