import { Typography } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { FirebaseAnalytics } from "../../../common/FirebaseAnalytics";
import { api } from "../../../constants/api";
import { useNavigate } from "react-router-dom";
import { FA_EVENTS } from "../../../constants/fa_events";
import { getContestData } from "../../../constants/api";
import ReactPixel from "react-facebook-pixel";

const Splash = () => {
  const navigate = useNavigate();
  const firebaseAnalyticsObjRef = useRef(new FirebaseAnalytics());

  useEffect(() => {
    (async () => {
      const firebaseAnalyticsObj = new FirebaseAnalytics();
      let fid = localStorage.getItem('fid');
      ReactPixel.trackCustom('track', FA_EVENTS.CLICK_POLL_ANSWER);
      if (fid === 'undefined' ||
        fid === 'null' ||
        !fid) {
        fid = await firebaseAnalyticsObj.setFirebaseInstalltionId();
      }
      if (fid) {
        localStorage.setItem("fid", fid);
        const resp = await fetch(`${api}/auth`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fId: fid,
            platform: "web",
          }),
        });
        const respJson = await resp.json();
        localStorage.setItem("token", JSON.stringify(respJson?.token));
        localStorage.setItem("userId", respJson?.user.id);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            id: respJson?.user?.id,
            displayName: respJson?.user?.displayName,
            status: respJson?.user?.status,
            uuid: respJson?.user?.uuid,
          })
        );
        firebaseAnalyticsObjRef.current.setUserId(respJson?.user.id);
        firebaseAnalyticsObjRef.current.sendEvent(
          FA_EVENTS.SPLASH_SCREEN_VIEW,
          {
            fId: respJson?.user.fId,
            location: JSON.stringify(respJson?.user.address),
          }
        );
        const fetchContest = async () => {
          try {
            const res = await getContestData();
            if (res.displayPopup === true) {
              navigate("/contest");
            } else {
              localStorage.setItem('active-tab-name', 'gg');
              navigate("/gg");
            }
          } catch (error) {
            console.error(error);
          }
        };
        fetchContest();
      }
    })();
  }, []);

  return (
    <div
      style={{
        boxSizing: "border-box",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url("./assets/images/gyaan_splash_bg.jpg")`,
        backgroundSize: "100% 100%",
      }}
    >
      <img
        src={"./assets/images/gyaan_splash_logo.png"}
        alt="gyaan logo"
        width={79}
        height={79}
      ></img>
      <Typography
        style={{
          fontFamily: "Merriweather !important",
          fontWeight: "900",
          fontSize: "42px",
        }}
      >
        Gyaan Beta
      </Typography>
      <Typography
        style={{
          fontFamily: "Merriweather !important",
          fontWeight: "600",
          fontSize: "21px",
        }}
      >
        AI Enabled Polls
      </Typography>
    </div>
  );
};

export default Splash;
