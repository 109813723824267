//@ts-nocheck
import React, { useEffect } from "react";
import "./App.css";
import { createTheme } from "@material-ui/core/styles";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./utils/const";
import {
  Route,
  Routes,
  BrowserRouter,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { HeaderWrapper } from "./components/DemoComponentsStyles/GlobalHeader";
import NewCard from "./components/DemoComponents/NewCard";
import { useMediaQuery } from "react-responsive";

import SearchScreen from "./components/DemoComponents/SearchArticle";
import Invalid from "./components/Invalid";
import Onboarding from "./components/DemoComponents/Onboarding/Onboarding";
import MyPolls from "./components/DemoComponents/Profile/MyPolls";
import Header from "./components/DemoComponents/Header";
import Box from "@mui/material/Box";
import ReactPixel from "react-facebook-pixel";
import Settings from "./components/DemoComponents/Profile/Settings";
import Splash from "./components/DemoComponents/Onboarding/Splash";
import TermsConditions from "./components/DemoComponents/TermsConditions";
import PrivacyPolicy from "./components/DemoComponents/PrivacyPolicy";
import RewardScreen from "./components/DemoComponents/RewardScreen";
// import { token } from "./constants/api";
import ContestPopup from "./components/DemoComponents/ContestPopup";

export const mtLightTheme = createTheme({
  palette: {
    type: "light",
    primary: primary(),
    secondary: secondary(),
  },
});

function App() {
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const isMobile = useMediaQuery({ maxWidth: 550, minWidth: 345 });
  const isMobile = useMediaQuery({ maxWidth: 850, minWidth: 345 });

  // Initializing pixel connector
  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    // console.log(
    //   window.orientation !== undefined && window.orientation !== 0,
    //   "true on landscape mode"
    // );
    // if (window.orientation !== undefined && window.orientation !== 0) {
    //   window.screen.lockOrientation("portrait");
    // }
    ReactPixel.init("641724964450432", undefined, options);
    ReactPixel.pageView();
  }, []);

  return (
    <div className="App" style={{ backgroundColor: "black" }}>
      <BrowserRouter>
        <HeaderWrapper>
          {isMobile ? <RouteElements /> : <Invalid />}
        </HeaderWrapper>
      </BrowserRouter>
    </div>
  );
}

function RouteElements() {
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    setToken(localStorage.getItem("token"));

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, null, window.location.pathname);
    });
  }, [pathname]);

  return (
    <>
      <Box sx={{ marginTop: "5px" }}>{token && <Header />}</Box>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/gg" element={<NewCard />} />
        <Route path="/polls/:pollId" element={<NewCard />} />
        <Route path="/categories/:pathCategory" element={<NewCard />} />
        <Route path="/:pathCategory/:pollId" element={<NewCard />} />
        {/* <Route path="/gpt" element={<SearchScreen />} /> */}
        <Route path="/mypolls" element={<MyPolls />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/pp" element={<PrivacyPolicy />} />
        <Route path="/rewards" element={<RewardScreen />} />
        <Route path="/contest" element={<ContestPopup />} />
      </Routes>
    </>
  );
}

export default App;

function primary() {
  return {
    50: PRIMARY_COLOR,
    100: PRIMARY_COLOR,
    200: PRIMARY_COLOR,
    300: PRIMARY_COLOR,
    400: PRIMARY_COLOR,
    500: PRIMARY_COLOR,
    600: PRIMARY_COLOR,
    700: PRIMARY_COLOR,
    800: PRIMARY_COLOR,
    900: PRIMARY_COLOR,
    A100: PRIMARY_COLOR,
    A200: PRIMARY_COLOR,
    A400: PRIMARY_COLOR,
    A700: PRIMARY_COLOR,
  };
}

function secondary() {
  return {
    50: SECONDARY_COLOR,
    100: SECONDARY_COLOR,
    200: SECONDARY_COLOR,
    300: SECONDARY_COLOR,
    400: SECONDARY_COLOR,
    500: SECONDARY_COLOR,
    600: SECONDARY_COLOR,
    700: SECONDARY_COLOR,
    800: SECONDARY_COLOR,
    900: SECONDARY_COLOR,
    A100: SECONDARY_COLOR,
    A200: SECONDARY_COLOR,
    A400: SECONDARY_COLOR,
    A700: SECONDARY_COLOR,
  };
}
