import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "../../DemoComponentsStyles/SearchScreen.css";
import { Grid, Slide, Typography, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import MultiProgress from "react-multi-progress";
import styles from "../../../styles/GyanGpt.module.css";
import { Diversity2 } from "@mui/icons-material";
import { getMyPolls } from "../../../constants/api";
import Backdrop from "@mui/material/Backdrop";



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 5,
  color: "white",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? "rgb(255 255 255)" : "#308fe8",
  },
}));

export default function MyPollsResultCard({
  listInnerRef,
}: any) {
  const [myPollsData, setMyPollsData] = React.useState<any>();
  const containerRef: React.Ref<any> = React.useRef();
  const [currPage, setCurrPage] = React.useState(0); // storing current page number
  const [prevPage, setPrevPage] = React.useState(-1);
  const [wasLastList, setWasLastList] = React.useState(false); // setting a flag to know the last list

  React.useEffect(() => {
    const fetchData = () => {
      getMyPolls(currPage, 10).then((res) => {
        if (!res || !res?.list?.length) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currPage);
        if (myPollsData) setMyPollsData([...myPollsData, ...(res?.list)]);
        else setMyPollsData([...(res?.list)]);
      });
    };
    if (!wasLastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, wasLastList, prevPage, myPollsData]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const totalHeight = scrollTop + clientHeight;
      if (totalHeight >= scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <div className="exploreCard"
      style={{ height: "80vh", top: 4, overflowY: "scroll" }}
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <>
        <CssBaseline />
        <Container maxWidth="sm" ref={containerRef}>
          <Slide
            direction="left"
            in={true}
            container={containerRef.current}
            timeout={{
              enter: 500,
            }}
          >
            <Box>
              <Box sx={{ bgcolor: "#0d0d0d", overflow: "hidden" }} />
              {myPollsData?.length > 0 ? myPollsData?.map((item: any) => (
                <Grid
                  container
                  columns={{ xs: 4, md: 12 }}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={"20px"}
                  style={{ overflowY: "scroll" }}
                  key={item?.id}
                >
                  <div className={styles.gptsharepollcontainer}>
                    <CardContent>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontFamily: "Merriweather",
                          fontSize: "18px",
                          fontWeight: 400,
                          paddingBottom: '10px'
                        }}
                        variant="h5"
                      >
                        {item?.question}
                      </Typography>
                      <div className={styles.options_container}>
                        {Object.keys(item?.optionStats).map((key, index) => {
                          return (
                            <div className={styles.width}>
                              <div key={index} className={styles.selected_progress} style={{ width: `${item?.optionStats[key]}%` }}>
                                <div className={styles.bar_number}>
                                  <p>{key}</p>
                                  <p>{item?.optionStats[key]}%</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </CardContent>
                  </div>
                </Grid>
              )) :
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "25px",
                    fontWeight: 700,
                    margin: "40px",
                  }}>
                  No polls found!
                </Typography>
              }
            </Box>
          </Slide>
        </Container>
      </>
    </div>
  );
}
