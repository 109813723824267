import React from "react";
import { useNavigate } from "react-router-dom";

import { getContestData, UpdateNumber } from "../../constants/api";

import styles from "../../styles/Contest.module.css";
import ReactPixel from "react-facebook-pixel";
import { FA_EVENTS } from "../../constants/fa_events";

const ContestPopup = () => {
  const [popup, setPopUp] = React.useState<any>({});
  const [inputValue, setInputValue] = React.useState<string>("");
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchContest = async () => {
      try {
        const res = await getContestData();
        setPopUp(res);
      } catch (error) {
        console.error(error);
      }
    };

    fetchContest();
  }, []);

  console.log(popup, "popup");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setInputValue(value);
    }
    setDisabled(value.length !== 10);
  };

  const handleUpdateNumber = () => {
    UpdateNumber(inputValue);
    ReactPixel.trackCustom('track', FA_EVENTS.CLICK_UPDATE_MOBILE_NUMBER);
    const tabName = localStorage.getItem('active-tab-name');
    if (tabName &&
      tabName !== 'undefined' &&
      tabName !== 'null' &&
      tabName === 'gg') {
      navigate("/gg");
      localStorage.setItem('active-tab-name', 'gg');
    } else if (tabName &&
      tabName !== 'undefined' &&
      tabName !== 'null' &&
      tabName === 'gpt') {
      navigate("/gpt");
      localStorage.setItem('active-tab-name', 'gpt');
    } else {
      navigate("/gg");
      localStorage.setItem('active-tab-name', 'gg');
    }
  };

  const hadleSkip = () => {
    const tabName = localStorage.getItem('active-tab-name');
    if (tabName &&
      tabName !== 'undefined' &&
      tabName !== 'null' &&
      tabName === 'gg') {
      localStorage.setItem('active-tab-name', 'gg');
      navigate("/gg");
    } else if (tabName &&
      tabName !== 'undefined' &&
      tabName !== 'null' &&
      tabName === 'gpt') {
      localStorage.setItem('active-tab-name', 'gpt');
      navigate("/gpt");
    } else {
      localStorage.setItem('active-tab-name', 'gg');
      navigate("/gg");
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.image} src="../assets/images/contest logo.png" />
        <div className={styles.enter_contest}>
          <h2>You have entered</h2>
          <h3>Daily Rewards</h3>
          <h4>Contest!</h4>
        </div>

        <div className={styles.paragraph}>
          <h2>Sign up and be the first to know</h2>
          <h2>when you hit the jackpot!</h2>
        </div>
        {popup.signupRequired === true ? (
          <div className={styles.input_number}>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder="Enter mobile number"
              maxLength={10}
            />
            <p>We won’t share your number with anyone</p>
          </div>
        ) : (
          <div className={styles.text}>You’ve already registered! </div>
        )}

        {popup.signupRequired === true ? (
          <div className={styles.button}>
            <button disabled={disabled} onClick={handleUpdateNumber}>
              Sign me up!
            </button>
            <p onClick={hadleSkip}>I don’t want rewards!</p>
          </div>
        ) : (
          <div className={styles.button}>
            <button
              onClick={() => {
                localStorage.setItem('active-tab-name', 'gg');
                navigate("/gg");
              }}
            >
              Okay!
            </button>
            <p></p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContestPopup;
