import React, { useEffect, useRef, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Poll } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Divider } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { api, getUserProfile } from "../../../constants/api";
import { v4 as uuidv4 } from 'uuid';
import { FirebaseAnalytics } from "../../../common/FirebaseAnalytics";
import { FA_EVENTS } from "../../../constants/fa_events";
import ReactPlayer from 'react-player';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 5,
  color: "white",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#D87C25" : "#308fe8",
  },
}));

const ProfileDrawer = ({
  isOpenProfileDrawer,
  setIsOpenProfileDrawer,
}: any) => {
  const navigate = useNavigate();
  const firebaseAnalyticsObjRef = useRef(new FirebaseAnalytics())
  const [loggedIn, setLoggedIn] = useState(false)
  const [userDisplayName, setUserDisplayName] = useState('');
  const [userProfileData, setUserProfileData] = useState({} as any);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setLoggedIn(true)
    }
    const profileData = (
      localStorage.getItem('profileData') &&
      localStorage.getItem('profileData') !== 'undefined' &&
      localStorage.getItem('profileData') !== 'null'
    ) ? JSON.parse(localStorage.getItem('profileData')!) : undefined;
    if (profileData) {
      setUserProfileData(profileData);
    } else {
      const fetchProfileData = async () => {
        try {
          const profileData = await getUserProfile();
          setUserProfileData(profileData);
          localStorage.setItem('profileData', JSON.stringify(profileData));
        } catch (error) {
          console.log(`Error occurred while fetching user profile.`);
        }
      }
      fetchProfileData();
    }
  }, [localStorage.getItem('profileData')!]);

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo')!);
      setUserDisplayName(userInfo.displayName);
    }
  }, [localStorage.getItem('userInfo')]);

  const parseJwt = async (userAuthToken: any) => {
    const fid = localStorage.getItem("fid")



    localStorage.clear();
    const base64Url = userAuthToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload: any = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const formattedData = JSON.parse(jsonPayload);
    if (formattedData?.email_verified) {
      fetch(`${api}/auth`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: formattedData?.email,
          name: formattedData?.name,
          pictures: formattedData?.picture,
          platform: "web",
          fId: fid
        }),
      }).then(async (response) => {
        return response.json().then((responseJson) => {
          localStorage.setItem("fid", fid as string);

          localStorage.setItem("token", JSON.stringify(responseJson?.token));

          localStorage.setItem("userInfo", JSON.stringify({
            id: responseJson?.user?.id,
            displayName: responseJson?.user?.displayName,
            status: responseJson?.user?.status,
            uuid: responseJson?.user?.uuid,
          }));

          // syncing signup events
          localStorage.setItem("sessionId", uuidv4());
          localStorage.setItem("userId", responseJson?.user.id);
          if (!responseJson?.user.existingUser) {
            firebaseAnalyticsObjRef.current.setUserId(responseJson?.user.id);
            firebaseAnalyticsObjRef.current.sendEvent(FA_EVENTS.SINGUP_EVENT, {
              emailId: responseJson?.user.emailId,
              fId: responseJson?.user.fId,
              location: JSON.stringify(responseJson?.user.address),
            });
          }
          setLoggedIn(true)
          localStorage.setItem('active-tab-name', 'gg');
          navigate("/gg", { state: responseJson?.token });
        });
      });
    }
  };


  return (
    <div>
      <SwipeableDrawer
        anchor={"right"}
        open={isOpenProfileDrawer}
        onClose={() => setIsOpenProfileDrawer(false)}
        onOpen={() => setIsOpenProfileDrawer(true)}
        minFlingVelocity={450}
      >
        <Box
          sx={{
            width: 250,
            bgcolor: "#030B13",
            height: "130vh",
            color: "#ffffff",
            pointerEvents: "all"
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 6, width: "100%", justifyContent: "space-between" }}
          >
            {!loggedIn ? <div style={{ display: "flex", flexDirection: "row" }}>

              {/* <img
                src="./assets/images/google logo.png"
                style={{
                  marginLeft: 20,   
                  marginTop:13 ,             
                  width: "25px", 
                  height: "25px", 
                }}
              />  */}

              {/* <Typography 
              sx={{
                marginTop: 1.7,
                marginLeft:1,
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "21px",
                letterSpacing: "0.3px",
                fontFamily: "Lato",
              }}
              onClick={() => login()}
              >
              Login/Sign up
              </Typography> */}
              <div style={{ marginTop: "6px", marginLeft: "10px", marginBottom: "6px" }}>

                <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    parseJwt(credentialResponse?.credential);
                  }}
                  size={"medium"}
                  cancel_on_tap_outside
                  theme={"filled_black"}
                  text={"signin_with"}
                  width={"10%"}
                  ux_mode={"popup"}
                />
              </div>
            </div> :
              <div style={{ paddingTop: 8, flexDirection: 'row', display: 'flex' }}>
                {/* <Avatar 
              style={{
                textAlign: "center",
                marginLeft: 18,
                width: 32,
                height: 32,
                position: "absolute", 
                marginTop:"5px", 
                //   zIndex:
              }}
              alt="Remy Sharp"
              src="./assets/images/avatar-colour.png"
            /> */}
                <Typography
                  sx={{
                    textAlign: "center",
                    marginTop: '19px',
                    marginLeft: "16px",
                    // fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "21px",
                    letterSpacing: "0.3px",
                    position: "relative",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontFamily: "Lato",

                    // paddingBottom: 10,
                  }}

                >
                  {userDisplayName}
                </Typography>


                {/* <MoreVertIcon 
              sx={{
                color: "#d87c25", 
                width: 28,
                height: 28,
                marginRight:-2, 
                fontFamily: "Lato", 
                marginLeft:'140px', 
                marginTop:'5px' 
              }} 
              onClick={() => navigate("/settings")}  
            />   */}
              </div>}
            <div style={{ paddingTop: 8 }}>


            </div>
          </div>
          <Box
            sx={{
              borderBottom: "1px solid  #7D7D7D",
              bgcolor: "#323232",
            }}
          >
            {/* <img
              src="./assets/images/profilebg.png"
              style={{
                zIndex: -1,
                marginTop: 200,
              }}
            /> */}
          </Box>
          {/* <Box sx={{ marginTop: 10 }}>
            <div
              style={{ display: "flex", flexDirection: "row", float: "left" }}
            >
              <img
                src="./assets/images/rebel-coloured.png"
                style={{ marginLeft: 20, marginTop: 4 }}
                width={"15px"}
                height={"18px"}
              />
              <Typography
                sx={{
                  marginTop: 0.5,
                  marginLeft: 1,
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  fontFamily: "Lato",
                }}
              >
                Rebel
              </Typography>
            </div>
            <div style={{ float: "right" }}>
              <Typography
                sx={{
                  marginTop: 0.5,
                  marginRight: 3,
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  fontFamily: "Lato",
                }}
              >
                62%
              </Typography>
            </div> 
          </Box> */}
          {/* <Box style={{ marginTop: 50, marginLeft: 25, width: "80%" }}> */}
          {/* <BorderLinearProgress
              valueBuffer={25}
              variant="determinate"
              value={62}
            /> */}
          {/* <Typography
              sx={{
                marginTop: 2,
                // marginRight: 3,
                textAlign: "center",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "0.3px",
                textDecorationLine: "underline",
                color: "#D87C25",
                fontFamily: "Lato",
              }}
            >
              What is Rebel?
            </Typography> */}
          <div style={{ position: "relative", top: 0, left: 0, right: 10 }}>
            {/* <img
              src="../assets/images/video-bg.png"
              style={{
                marginTop: 17,
                marginLeft: 16,
                position: "relative",
                opacity: "0.8",
              }}
            />
            <img
              src="../assets/images/play.png"
              style={{ right: 18, top: 64, left: 110, position: "absolute" }}
            /> */}
            <ReactPlayer 
            url="<https://youtu.be/TKESeAj2dP0>"
            height="20vh"
            width="65vw"
            controls={true}
            />
          </div>

          <Typography
            sx={{
              marginTop: '10px',
              marginLeft: 10,
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
              letterSpacing: "0.3px",
              fontFamily: "Lato",
            }}
          >
            User Testimonial!
          </Typography>
          {/* </Box> */}
          <Divider
            style={{ background: "rgba(255, 255, 255, 0.1)", marginTop: 10 }}
          />
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                float: "left",
              }}
            >
              <Typography
                sx={{
                  marginTop: '15px',
                  marginLeft: 2,
                  fontWeight: 600,
                  fontSize: "21px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  color: "#D87C25",
                  fontFamily: "Lato",
                }}
              >
                {userProfileData?.pollsTaken}
              </Typography>
              <Typography
                sx={{
                  marginTop: '4px',
                  marginLeft: 2,
                  fontWeight: 600,
                  fontSize: "12px",
                  letterSpacing: "0.3px",
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "Lato",
                }}
              >
                Polls Taken
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                float: "right",
              }}
            >
              <Typography
                sx={{
                  marginTop: '15px',
                  marginRight: '16px',
                  fontWeight: 600,
                  fontSize: "21px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  textAlign: "right",
                  color: "#D87C25",
                  fontFamily: "Lato",
                }}
              >
                {userProfileData?.pollsPosted}
              </Typography>
              <Typography
                sx={{
                  marginTop: '4px',
                  marginRight: '16px',
                  fontWeight: 600,
                  fontSize: "12px",
                  letterSpacing: "0.3px",
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "Lato",
                }}
              >
                Polls Shared
              </Typography>
            </div>
          </Box>
          {/* <Divider
            style={{ background: "rgba(255, 255, 255, 0.1)", marginTop: 80 }}
          />  */}


          <Divider
            style={{ background: "rgba(255, 255, 255, 0.1)", marginTop: 70 }}
          />
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                float: "left",
                marginTop: '4px',
                marginBottom: '4px',

              }}
            >
              <Poll
                style={{
                  marginLeft: '14px',
                  marginTop: 15,
                  width: "24px",
                  height: "24px",
                  color: "#D87C25",
                }}
              />
              <Typography
                sx={{
                  marginTop: 2,
                  marginLeft: '7px',
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  fontFamily: "Lato",
                }}
                onClick={() => {
                  setIsOpenProfileDrawer(false);
                  navigate("/mypolls");
                }}
              >
                My Polls
              </Typography>
            </div>
          </Box>
          <Divider
            style={{ background: "rgba(255, 255, 255, 0.1)", marginTop: 60 }}
          />
          {/* <Box>
            <div
              style={{
                display: "flex",
                
                float: "left",
              }}
            >
              <img
                src="./assets/images/share.png"
                style={{
                  marginLeft: 20,
                  marginTop: 15,
                  width: "24px",
                  height: "24px",
                }}
              /> 
              <Typography
                sx={{
                  marginTop: 2.3,
                  marginLeft: 2,
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  fontFamily: "Lato",
                }}
              >
                Share My Profile
              </Typography>
            </div> 
           
          </Box>  */}

          <div onClick={() => {
            setIsOpenProfileDrawer(false);
            navigate("/terms")
          }} style={{ flexDirection: "row", display: "flex", marginTop: 4 }}>
            <IconButton>
              <DescriptionIcon style={{ color: "#D87C25", marginLeft: '4px' }} />

              <Typography
                sx={{
                  marginLeft: "7px",
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                Terms and condition
              </Typography>
            </IconButton>
          </div>

          <div onClick={() => {
            setIsOpenProfileDrawer(false);
            navigate("/pp")
          }} style={{ flexDirection: "row", display: "flex", marginTop: '8px' }}>
            <IconButton>
              <VerifiedUserIcon style={{ color: "#D87C25", marginLeft: '5px' }} />

              <Typography
                sx={{
                  marginLeft: "7px",
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "21px",
                  letterSpacing: "0.3px",
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                Privacy Policy
              </Typography>
            </IconButton>
          </div>
          {/* <Divider
            style={{ background: "rgba(255, 255, 255, 0.1)", marginTop: 60 }}
          /> */}
          <div style={{ marginTop: '38px', marginRight: '16px', marginLeft: '16px' }}>
            {/* <div style={{flexDirection:'row' , display:'flex'}} >
        <img
                src="./assets/images/reachout-logo.png"
                style={{
                  marginLeft: 20,   
                  bottom:70,                
                  width: "64px", 
                  height: "64px", 
                }}
              /> 
              <div>
              <h1 style={{color:'white' , fontSize:'10px' , marginLeft:'7px'}}>Reach out to us @</h1> 
              <h1 style={{color:'#D87C25' , fontSize:'10px' , marginLeft:'7px' , bottom:10}}>gyaanpgt@gmail.com</h1> 
              </div>
              </div>  */}
            <img
              style={{ height: '75px', width: '216px' }}
              src="../assets/images/Contact.png"
            />

          </div>

        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default ProfileDrawer;
