import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId as analyticsSetUserId, logEvent, } from "firebase/analytics";
import {getId,getInstallations} from "firebase/installations";

const firebaseConfig = {
    apiKey: "AIzaSyAkIuqkWbzY6zJzJD-GypvbYpEA1NVMiMI",
    authDomain: "gyaan-prod.firebaseapp.com",
    projectId: "gyaan-prod",
    storageBucket: "gyaan-prod.appspot.com",
    messagingSenderId: "353089243162",
    appId: "1:353089243162:web:5cfa3f7f248e36b201e9a3",
    measurementId: "G-QS2NPM1K8C"
};

export class FirebaseAnalytics {
    private app;
    private analytics;
    private installations;
    public fid:any;

    constructor() {
        this.app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(this.app);
        this.installations=getInstallations(this.app);
    }

    public async setFirebaseInstalltionId(){
        this.fid=await getId(this.installations);
        return this.fid;
    }
    public setUserId(userId?: string) {
        if (userId) {
            analyticsSetUserId(this.analytics, userId);
        }
    }

    private appendCommonProperties(eventData: any) {
        eventData['sessionId'] = localStorage.getItem('sessionId');
        eventData['userId'] = localStorage.getItem('userId');
    }

    public sendEvent(eventName: string, evtData: any) {
        this.appendCommonProperties(evtData);
        logEvent(this.analytics, eventName, evtData);
    }

}