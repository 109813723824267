import React from "react";
import { Avatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GeneratePollCard from "../GeneratePoll/GeneratePollCard";
import MyPollsResultCard from "./MyPollsResultCard";
import "../../DemoComponentsStyles/MyPolls.css";

const MyPolls = ({ setIsGenratePoll }: any) => {
  const navigate = useNavigate();
  const listInnerRef = React.useRef();
  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <div className="myPolls" style={{height: '0px'}}>
        <IconButton style={{marginLeft: '16px'}} onClick={() => {
          const activeTab = localStorage.getItem('active-tab-name');
          if (!activeTab || activeTab === 'gg') {
            localStorage.setItem('active-tab-name', 'gg');
            navigate("/gg")
          } else {
            localStorage.setItem('active-tab-name', 'gpt');
            navigate("/gpt")
          }
        }}>
          <KeyboardBackspaceRoundedIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <Typography
        sx={{
          textAlign: "initial",
          fontWeight: 600,
          fontSize: "17px",
          letterSpacing: "0.3px",
          fontFamily: 'Lato',
          marginLeft: '60px',
          marginTop: '7px',
        }}
      >
        My Polls
      </Typography>
      <MyPollsResultCard 
        listInnerRef={listInnerRef}/>
    </div>
  );
};

export default MyPolls;
