import React from "react";
import { Box, IconButton } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PublicIcon from "@mui/icons-material/Public";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { getRewards } from "../../constants/api";

import styles from "../../styles/UpdatedGyanGG.module.css";

interface PollCounterDetail {
  dayWisePollsCount: number;
  totalPollsCount: number;
}

interface Rewards {
  title: string;
  description: string;
  image: any;
}

const RewardScreen = () => {
  const navigate = useNavigate();
  const [rewards, setRewards] = React.useState<Rewards>({
    title: "",
    description: "",
    image: "",
  });
  const [pollCounterDetail, setPollCounterDetail] =
    React.useState<PollCounterDetail>({
      dayWisePollsCount: 0,
      totalPollsCount: 0,
    });
  const [giveaway, setGiftAway] = React.useState([]);
  const [validTill, setValidTill] = React.useState<number>(0);
  const [timeRemaining, setTimeRemaining] = React.useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    const fetchRewards = async () => {
      try {
        const res = await getRewards();
        setRewards(res.rewards[0]);
        setValidTill(res.rewards[0].validTill);
        setGiftAway(res.rewards.slice(1));
        setPollCounterDetail(res.pollCounterDetail);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRewards();
  }, []);

  const getTimeRemaining = () => {
    const now = new Date().getTime();
    const timeDiff = validTill - now;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    return { hours, minutes, seconds };
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);
    return () => clearInterval(timer);
  }, [validTill]);

  return (
    <div
      style={{
        overflow: "scroll",
        scrollBehavior: "smooth",
        backgroundImage: "url('./assets/images/BG1_new.png')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        marginBottom: "50px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "4px",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <IconButton
          onClick={() => {
            const activeTab = localStorage.getItem("active-tab-name");
            console.log(activeTab,"activeTab");
            if (!activeTab || activeTab === "gg") {
              localStorage.setItem("active-tab-name", "gg");
              navigate("/gg");
            } else {
              localStorage.setItem("active-tab-name", "gpt");
              navigate("/gpt");
            }
          }}
        >
          <KeyboardBackspaceRoundedIcon style={{ color: "white" }} />
        </IconButton>
      </Box>
      <div style={{ marginLeft: "19px", marginTop: "60px" }}>
        <h1
          style={{
            fontFamily: "Merriweather",
            fontWeight: 700,
            fontSize: "32px",
          }}
        >
          Answer and
        </h1>
        <h1
          style={{
            fontFamily: "Merriweather",
            marginTop: -19,
            fontWeight: 700,
            fontSize: "32px",
          }}
        >
          Win Rewards!
        </h1>

        <div style={{ flexDirection: "row", display: "flex" }}>
          <h1
            style={{
              fontFamily: "lato",
              marginTop: -5,
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            Answer
          </h1>
          <h1
            style={{
              fontFamily: "lato",
              marginTop: -5,
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "3px",
              color: "#D87C25",
            }}
          >
            20 polls in one day
          </h1>
          <h1
            style={{
              fontFamily: "lato",
              marginTop: -5,
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "4px",
            }}
          >
            to enter
          </h1>
        </div>

        {/* <div style={{ flexDirection: "row", display: "flex" }}>
          <img
            src={rewards.image?.thumbnail}
            style={{
              height: "149.49px",
              width: "118.02px",
              marginLeft: "36px",
              marginTop: "18px",
            }}
          />
          <div style={{ marginLeft: -10 }}>
            <h1
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                fontFamily: "lato",
                marginTop: "79px",
                marginLeft: "36px",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              {rewards.title}
            </h1>
            <h1
              style={{
                fontFamily: "lato",
                marginTop: -7,
                marginLeft: "36px",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {rewards.description}
            </h1>
          </div>
        </div>

        <div style={{ flexDirection: "row", display: "flex" }}>
          <h1
            style={{
              fontFamily: "lato",
              marginTop: "47px",
              fontWeight: 400,
              fontSize: "16px",
              marginLeft: "80px",
              color: "rgba(255, 255, 255, 0.6)",
            }}
          >
            Expiring in
          </h1>
          <AccessTimeIcon
            style={{
              marginTop: "51px",
              marginLeft: "4px",
              height: "15px",
              width: "15px",
            }}
          />
          <h1
            style={{
              fontFamily: "lato",
              marginTop: "47px",
              fontWeight: 700,
              fontSize: "16px",
              marginLeft: "4px",
              color: "#D87C25",
            }}
          >
            {getTimeRemaining().hours}h {getTimeRemaining().minutes}m{" "}
            {getTimeRemaining().seconds}s
          </h1>
        </div> */}

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            marginRight: "19px",
          }}
        >
          <PublicIcon
            style={{
              color: "#D87C25",
              marginTop: "47px",
              height: "24px",
              width: "24px",
            }}
          />
          <h1
            style={{
              fontFamily: "lato",
              marginTop: "48px",
              fontWeight: 700,
              fontSize: "18px",
              marginLeft: "7px",
            }}
          >
            Global Poll Counter
          </h1>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            marginTop: "7px",
            marginRight: "19px",
            gap: 10,
          }}
        >
          <div
            style={{
              width: "122px",
              height: "40px",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              flexDirection: "row",
              display: "flex",
              borderRadius: "25px",
            }}
          >
            <h1
              style={{
                fontFamily: "lato",
                fontSize: "16px",
                marginLeft: "14px",
                color: "rgba(255, 255, 255, 0.6)",
                marginTop: "10px",
              }}
            >
              Daily
            </h1>
            <h1
              style={{
                marginLeft: "7px",
                fontFamily: "lato",
                fontSize: "18px",
                marginTop: 9,
                marginRight: "14px",
              }}
            >
              {pollCounterDetail.dayWisePollsCount}
            </h1>
          </div>
          <div
            style={{
              width: "130px",
              height: "40px",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              flexDirection: "row",
              display: "flex",
              borderRadius: "25px",
            }}
          >
            <h1
              style={{
                fontFamily: "lato",
                fontSize: "16px",
                marginLeft: "14px",
                color: "rgba(255, 255, 255, 0.6)",
                marginTop: "10px",
              }}
            >
              Total
            </h1>
            <h1
              style={{
                marginLeft: "7px",
                fontFamily: "lato",
                fontSize: "18px",
                marginTop: 9,
                marginRight: "14px",
              }}
            >
              {pollCounterDetail.totalPollsCount}
            </h1>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 35 }}>
        {/* <h1
          style={{
            fontFamily: "Merriweather",
            marginTop: 19,
            fontWeight: 700,
            fontSize: "28px",
          }}
        >
          Giveaway Alert!
        </h1> */}
        {giveaway &&
          giveaway.map((item: any) => {
            return (
              <>
                  <div className={styles.transform_image}>
                    <img
                      src={item.image?.thumbnail}
                      style={{
                        height: "149.49px",
                        width: "118.02px",
                        marginLeft: "45px",
                        marginTop: "18px",
                      }}
                    />
                    <div>
                      <h1
                        style={{
                          color: "rgba(255, 255, 255, 0.6)",
                          fontFamily: "lato",
                          marginTop: "79px",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        {item.title}
                      </h1>
                      <h1
                        style={{
                          fontFamily: "lato",
                          marginTop: -7,
                          fontWeight: 700,
                          fontSize: "24px",
                          width: "80%",
                          color: "rgba(255, 255, 255, 0.6)",
                          WebkitTextFillColor: "rgba(255, 255, 255, 0.6)",
                          WebkitTextStroke: "0.25px #FFFFFF",
                          WebkitTextStrokeWidth: "0.25px",
                          textShadow:
                            "0.25px 0 0 #FFFFFF, 0 -0.25px 0 #FFFFFF, -0.25px 0 0 #FFFFFF, 0 0.25px 0 #FFFFFF",
                        }}
                      >
                        {item.description}
                      </h1>
                    </div>
                  </div>
                <div className={styles.trapezoid}>
                </div>

                <div
                  className={styles.rewards_container}
                  // style={{
                  //   flexDirection: "row",
                  //   display: "flex",
                  //   background:
                  //     "linear-gradient(360deg, #00070C 0%, rgba(0, 7, 12, 0.510417) 57.29%, rgba(0, 7, 12, 0) 100%)",
                  // }}
                >
                  <LockOutlinedIcon
                    style={{
                      // marginTop: "44px",
                      marginLeft: "25px",
                      height: "18px",
                      width: "18px",
                    }}
                  />

                  <h1
                    style={{
                      fontFamily: "lato",
                      // marginTop: "47px",
                      fontWeight: 400,
                      fontSize: "14px",
                      marginLeft: "4px",
                    }}
                  >
                    Unlocks after
                  </h1>

                  <h1
                    style={{
                      fontFamily: "lato",
                      // marginTop: "47px",
                      fontWeight: 900,
                      fontSize: "14px",
                      marginLeft: "4px",
                      color: "#D87C25",
                    }}
                  >
                    {item.rewardsUnlockText}
                  </h1>
                  <h1
                    style={{
                      fontFamily: "lato",
                      // marginTop: "47px",
                      fontWeight: 400,
                      fontSize: "14px",
                      marginLeft: "5px",
                    }}
                  >
                    on Gyaan.GG globally
                  </h1>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default RewardScreen;
