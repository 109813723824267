export const FA_EVENTS = {
    SINGUP_EVENT: 'signup-event',
    SPLASH_SCREEN_VIEW: 'splash-view',
    CLICK_EXPLORE: 'click:explore',
    CLICK_ANSWER: 'click:answer',
    CLICK_REWARD: 'click:rewards',
    CLICK_PROFILE: 'click:profile',
    CLICK_SEARCH: 'click:search',
    CLICK_POLL: 'click:poll',
    CLICK_ARTICLE: 'click:article',
    CLICK_GENERATE_POLL: 'click:generatePoll',
    CLICK_POLL_ANSWER: 'click:pollAnswer',
    CLICK_BULB: 'click:bulb',
    CLICK_SHARE: 'click:share',
    CLICK_NEXT: 'click:next',
    CLICK_NEXT_POLL: 'click:nextPoll',
    CLICK_VIEW_ARTICLE: 'click:viewArticle',
    CLICK_UPDATE_MOBILE_NUMBER: 'click:updateMobileNumber',
    SELECT_CATEGORY: 'select:category',
};