import React from 'react'
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from '@mui/material';

const Invalid = () => {
    // const [open, setOpen] = React.useState<boolean>(true)
  return (
    <div>
      <Dialog open={true}>
        <Box sx={{padding:'5px'}}>
          <DialogTitle>Invalid Screen Size</DialogTitle>
          <Typography variant="h6" sx={{fontFamily:'sans-serif', fontWeight: 800, fontSize: '20px', paddingLeft: '12px'}}>
            This device is not compatible for our site
          </Typography>
        </Box>
      </Dialog>
    </div>
  );
}

export default Invalid