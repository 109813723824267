import React from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CreateIcon from "@mui/icons-material/Create";
import TranslateIcon from "@mui/icons-material/Translate";
import DescriptionIcon from "@mui/icons-material/Description"; 
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; 
import LogoutIcon from '@mui/icons-material/Logout'; 
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        className="myPolls"
      >
        <IconButton sx={{ top: 25 }} onClick={() => navigate(-1)}>
          <KeyboardBackspaceRoundedIcon style={{ color: "white" }} />
        </IconButton>

        <Typography sx={{}}></Typography>

        <Box sx={{}}>
          <Avatar
            style={{
              textAlign: "center",
              marginTop: 25,
              marginLeft: "27%",
              width: "100px",
              height: "100px",
              position: "absolute",
            }}
            alt="Remy Sharp"
            src="./assets/images/profile-avatar.png"
          />
        </Box>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography
          sx={{
            marginTop: 13,
            marginLeft: 18,
            textAlign: "center",
            fontWeight: 400,
            fontSize: "19px",
            lineHeight: "21px",
            letterSpacing: "0.3px",
          }}
        >
          anuragkpr
        </Typography>

        <IconButton>
          <CreateIcon style={{ color: "#D87C25", marginTop: "94px" }} />
        </IconButton>
      </div>

      <div style={{ flexDirection: "row", display: "flex", marginTop: 28 }}>
        <TranslateIcon style={{ color: "#D87C25", marginLeft: 18 }} />

        <Typography
          sx={{
            marginLeft: "10px",
            textAlign: "center",
            fontWeight: 400,
            fontSize: "19px",
            lineHeight: "21px",
            letterSpacing: "0.3px",
            color: "white",
            fontFamily: "Lato",
          }}
        >
          Language
        </Typography>

        <Typography
          sx={{
            marginLeft: "auto",
            textAlign: "center",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "21px",
            letterSpacing: "0.3px",
            color: "grey",
            marginRight: 7,
            fontFamily: "Lato",
          }}
        >
          English
        </Typography>
      </div>

      <div style={{ flexDirection: "row", display: "flex", marginTop: 70 }}>
        <IconButton>
          <DescriptionIcon style={{ color: "#D87C25", marginLeft: 14 }} />

          <Typography
            sx={{
              marginLeft: "10px",
              textAlign: "center",
              fontWeight: 400,
              fontSize: "19px",
              lineHeight: "21px",
              letterSpacing: "0.3px",
              color: "white",
              fontFamily: "Lato",
            }}
          >
            Terms and condition
          </Typography>
        </IconButton>
      </div>

      <div style={{ flexDirection: "row", display: "flex", marginTop: 25 }}>
        <IconButton>
          <VerifiedUserIcon style={{ color: "#D87C25", marginLeft: 14 }} />

          <Typography
            sx={{
              marginLeft: "10px",
              textAlign: "center",
              fontWeight: 400,
              fontSize: "19px",
              lineHeight: "21px",
              letterSpacing: "0.3px",
              color: "white",
              fontFamily: "Lato",
            }}
          >
            Privacy Policy 
          </Typography>
        </IconButton>
      </div> 

      <div style={{ flexDirection: "row", display: "flex", marginTop:110 }}>
        <IconButton onClick={() => {
          const activeTab = localStorage.getItem('active-tab-name');
          if (!activeTab || activeTab === 'gg') {
            localStorage.setItem('active-tab-name', 'gg');
            navigate("/gg")
          } else {
            localStorage.setItem('active-tab-name', 'gpt');
            navigate("/gpt")
          }
        }} >
          <LogoutIcon style={{ color: "#D87C25", marginLeft: 14 }} />

          <Typography
            sx={{
              marginLeft: "10px",
              textAlign: "center",
              fontWeight: 400,
              fontSize: "19px",
              lineHeight: "21px",
              letterSpacing: "0.3px",
              color: "white",
              fontFamily: "Lato",
            }}
          >
            Logout
          </Typography>
        </IconButton>
      </div>
    </div>
  );
};

export default Settings;
