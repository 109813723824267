import React from "react";
import { Card, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import { Box, Typography } from "@material-ui/core";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          alignItems: "center",
        }}
      >
        <IconButton>
          <KeyboardBackspaceRoundedIcon
            onClick={() => {
              const activeTab = localStorage.getItem("active-tab-name");
              if (!activeTab || activeTab === "gg") {
                localStorage.setItem("active-tab-name", "gg");
                navigate("/gg");
              } else {
                localStorage.setItem("active-tab-name", "gpt");
                navigate("/gpt");
              }
            }}
            style={{ color: "white" }}
          />
        </IconButton>
        <Typography
          style={{
            fontWeight: 900,
            fontSize: "20px",
            marginLeft: "4px",
            color: "white",
            fontFamily: "Lato",
          }}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Box
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          marginBottom: "60px",
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            margin: "10px",
            overflow: "auto",
          }}
        >
          <Box style={{ backgroundColor: "white", height: "10px" }} />
          <Typography
            style={{
              marginBottom: "40px",
              fontFamily: "Lato",
              fontSize: "17px",
              color: "#000",
            }}
          >
            Thank you for choosing to be part of our community at Gyaan (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at gyaangpt@gmail.com
            When you visit our website www.gyaangpt.com and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue the use of our Site and our services.
            This privacy policy applies to all information collected through our website (such as www.gyaangpt.com), and/or any related services. Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.
            What Information do we collect?
            Personal information you disclose to us
            In Short: We collect personal information that you provide to us such as your google email address.
            We collect personal information that you voluntarily provide to us when registering at the Sites, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites, or otherwise contacting us.
            The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make, and the services and features you use. The personal information we collect can include the following:
            Name and Contact Data. We collect your first and last name, mobile numbers, and other similar contact data.



            Information automatically collected
            In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Site.
            We automatically collect certain information when you visit, use or navigate the Site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site and other technical information. This information is primarily needed to maintain the security and operation of our Sites, and for our internal analytics and reporting purposes.
            How do we use your information?
            Your participation in these polls can lead to the development of new products and services or the improvement of current ones.
            How do we keep your information safe?
            In Short: We aim to protect your personal information through a system of organizational and technical security measures.
            We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, the transmission of personal information to and from our Sites is at your own risk. You should only access the services within a secure environment.
            Management of E-mail Address and Password
            After signup, the Member is required to use their designated e-mail address in order to participate in any polls.
            The Member shall assume full responsibility for the management, use, and confidentiality of the e-mail address, issued to the registered Member.
            The Member shall not be allowed to assign, lend, or disclose his/her designated ID or password to any third party.
            Gyaan shall assume no responsibility for any damage associated with the Member's negligence in the use of the e-mail address and password, or that by a third party.
            Do we make updates to this policy?
            In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
            We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
            Summary
            We are committed to protecting user privacy
            At Gyaan, Keeping your information private is just as important to us as keeping your opinions. No personal information about you will be sold, distributed, or transferred without your explicit permission. Your information is 100% safe and secure.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
