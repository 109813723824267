import React from 'react'
import styled from "styled-components";

export const HeaderWrapper = styled.div`
min-width: 320px;
margin: 0 auto;
background-color: black;
color: white;
align-items: center;
`;
// max-width: 450px;
// min-height: 100vh;
